import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Section from '../components/layout/Section';
// import Hero from '../components/home/Hero';
// import Why from '../components/home/Why';

type CityInfo = {
  cityName,
  slug,
};

type IndexQueryData = {
  allCaviarCity:{
    nodes: CityInfo[]
  }
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};

export const query = graphql`
  query {
    allCaviarCity {
      nodes {
        cityName
        slug
      }
    }
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;

export default function Index({ data }:{ data: IndexQueryData }) {
  const cities = data.allCaviarCity.nodes;
  return <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={data.site.siteMetadata.description} />
      <title>{data.site.siteMetadata.name}</title>
    </Helmet>
    <Section id="cities">
      <fieldset>
        <legend>Cities</legend>
        <ul>
          {cities.map(({slug, cityName}, c)=><li key={c}><Link to={`/city/${slug}`}>{cityName}</Link></li>)}
        </ul>
      </fieldset>
    </Section>
  </>;
}
